export default defineNuxtRouteMiddleware(async () => {
  const { getSessionToken } = useProfileStore();
  const sessionToken = getSessionToken();
  const { localizePath } = useProjectMethods();

  if (!sessionToken) {
    if (import.meta.server) {
      return navigateTo(localizePath('/login'));
    } else {
      const profileStore = useProfileStore();
      if (profileStore.isLoggedIn) await profileStore.removeSession();
      return navigateTo(localizePath('/login'));
    }
  }
});
